import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const CryptoAlsEigenkapital = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Crypto und NFTs als Eigenkapital" showCalc={false} />
            <Article>
                <p>
                    In letzter Zeit beginnt sich unter Personen, die erfolgreich in Kryptowährungen investiert haben,
                    ein Trend abzuzeichnen: der Kauf von Immobilien mit Bitcoin &amp; Co. Aber hat diese Entwicklung
                    tatsächlich das Potenzial, den Immobilienmarkt nachhaltig zu verändern, wie man immer wieder hört?
                    Und was zur Hölle sind eigentlich NFTs? Wir erklären es dir im folgenden Beitrag.
                </p>
                <hr />

                <h2>Erstmal langsam: Was sind eigentlich Cryptocurrencies?</h2>
                <p>
                    Unter einer “Cryptocurrency” kannst du dir ganz grundsätzlich eine digitale Währung vorstellen, die
                    Geldtransfers ohne Banken und Gebühren ermöglicht. Ein prominentes Beispiel ist Bitcoin: 2009 als
                    erste Kryptowährung entwickelt, hat Bitcoin aufgrund spektakulärer Kursentwicklungen eine beachtlich
                    große Präsenz in den Medien. Es gibt Phasen, in denen kaum ein Tag ohne eine Meldung zu der
                    Kryptowährung vergeht.
                </p>
                <p>
                    Doch wie funktionieren Kryptowährungen eigentlich? Es ist im Grunde ganz einfach: Hast du dich erst
                    einmal bei einer Kryoptobörse registriert, so kannst du dort, gegen sogenanntes Fiat-Geld, Einheiten
                    einer Kryptowährung kaufen. Ein virtuelles Wallet dient der Aufbewahrung der erworbenen Einheiten.
                    Sobald du dieses auf deinem Gerät installiert hast, wird automatisch eine Adresse generiert, mit der
                    du deine Transaktionen durchführen kannst. Abgewickelt werden sie alle in einem gemeinsamen
                    Buchungssystem, der “Blockchain”.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Kreditrechner
                    </a>
                </p>
                <hr />

                <h2>Das bedeutet NFT</h2>
                <p>
                    Die Abkürzung NFT steht für non-fungible token. Es handelt sich dabei also um virtuelle Einheiten,
                    die – etwa im Gegensatz zu einzelnen Bitcoins – nicht austauschbar sind. Jedes NFT ist
                    unverwechselbar und lässt sich zudem einem*einer Eigentümer*in zuordnen. Als NFT kommt damit
                    grundsätzlich jede digitale Entität in Frage. So verkauft die Künstlerin Lana Denina ihre Bilder als
                    NFTs und hat damit in weniger als einem Jahr über 350 000 Euro verdient, wie sie in einem{" "}
                    <a
                        href="https://www.derstandard.at/story/2000131840525/junge-kuenstlerin-verdient-mit-ihren-nft-gemaelden-ueber-350-000"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Interview
                    </a>{" "}
                    mit dem “Standard” berichtete.
                </p>
                <hr />

                <h2>Wie kann man sich den Wert von Crypto und NFT vorstellen?</h2>
                <p>
                    Sowohl Einheiten von Kryptowährungen als auch NFTs haben natürlich einen Wert. NFTs erhalten ihren
                    Wert dadurch, dass die zu einzigartigen und fälschungssicheren Sammlerstücken werden. Bei
                    Kryptowährungen ist der Börsenwert der Gesamtwert aller Coins, die erzeugt wurden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Können Crypto und NFT als Eigenkapital verwendet werden?</h2>
                <p>
                    Mitunter ja: es lassen sich mehrere Beispiele anführen, in denen Immobilien mit Bitcoins gekauft
                    bzw. zum Kauf mit Bitcoins angeboten wurden. Noch als Experiment angelegt, das die Marktfähigkeit
                    von Krypto-Transaktionen auf dem Immobiliensektor testen sollte, war ein Inserat des Investors Felix
                    Haas: Er versuchte, seine unmittelbar am Englischen Garten in München gelegene Zwei-Zimmer-Wohnung
                    um 38,7 Bitcoin zu verkaufen. Optional konnte das Objekt, dem damaligen Wechselkurs entsprechend,
                    auch um 550 000 Euro erworben werden. Auf den entsprechenden Facebook-Post hätten sich zahlreiche
                    Personen gemeldet, erzählte Haas dem{" "}
                    <a
                        href="https://www.handelsblatt.com/finanzen/immobilien/immobilienkauf-mit-kryptowaehrungen-haeuser-zum-bitcoin-tarif-locken-kaeufer/21144320.html"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Handelsblatt
                    </a>
                    .
                </p>
                <p>
                    Eine Nummer größer geht es in den USA: Die “Magnum Real Estate Group” bietet im New Yorker Stadtteil
                    Manhattan aktuell ein Haus an, das im Gegensatz zur Wohnung von Felix Haas ausschließlich mit
                    Bitcoin bezahlt werden kann. Bitcoin &amp; Co setzen sich auf dem Immobilienmarkt also mehr und mehr
                    durch. Kein Wunder, haben Kryptowährungen gegenüber ‘fiat-Geld’ doch vor allem einen Vorteil:
                    geringere Transaktionskosten, auch bei internationalen Käufen. Umgekehrt bergen Geschäfte mit
                    Kryptowährungen allerdings das Risiko von Hackerangriffen. Darüber hinaus ist der Kurs von Bitcoin
                    &amp; Co. zum Teil erheblichen Schwankungen unterworfen. All das solltest du beachten, wenn du
                    überlegst, in eine Kryptowährung zu investieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Was die Zukunft bringt.</h2>
                <p>
                    Obwohl Kryptowährungen bereits von zahllosen Menschen genutzt und in machen Fällen sogar auf dem
                    Immobilienmarkt als Zahlungsmittel akzeptiert werden, haben sie wohl auch langfristig nicht das
                    Potenzial, konventionelle Währungen zu ersetzen. Gerade in Staaten, die über eine stabile Währung
                    verfügen, werden Bitcoin und andere Cryptocurrencies voraussichtlich weiterhin die Funktion einer
                    Geldanlage übernehmen: gelten sie doch als besonders sicher gegenüber inflationsbedingter
                    Wertminderung. Das bedeutet allerdings, dass es in Zukunft auch beim Erwerb einer Immobilie üblich
                    sein wird, Bitcoin als Sicherheit bzw. als Eigenkapital zu verwenden.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"cryptoAlsEigenkapital"}></BreadcrumbList>
            <ArticleStructuredData page={"cryptoAlsEigenkapital"} heading={"Crypto und NFTs als Eigenkapital"} />
        </Layout>
    );
};

export default CryptoAlsEigenkapital;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.crypto-als-eigenkapital", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
